import { Link } from 'components/Basic/Link/Link';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import 'keen-slider/keen-slider.min.css';
import { FC } from 'react';
import { ImageSizeType } from 'types/image';

type BannersSliderItemProps = {
    link: string;
    mobileImage: ImageSizeType | null;
    desktopImage: ImageSizeType | null;
    name: string;
    buttonText: string;
    description?: string | null;
};

const bannerSliderItemImageTwClass = 'block h-[210px] w-full object-cover lg:h-[400px]';

export const BannersSliderItem: FC<BannersSliderItemProps> = ({
    mobileImage,
    desktopImage,
    link,
    name,
    description,
    buttonText,
}) => {
    const isDesktop = useMediaMin('lg');
    return (
        <div className="keen-slider__slide relative flex w-full flex-[0_0_100%] flex-col overflow-hidden rounded-md lg:h-fit">
            {mobileImage === null && desktopImage === null && (
                <img className={bannerSliderItemImageTwClass} src="images/optimized-noimage.png" />
            )}
            {mobileImage && (
                <picture className="lg:hidden">
                    {mobileImage.additionalSizes.map((size) => (
                        <source key={size.url} srcSet={size.url} media={size.media} />
                    ))}
                    <img className={bannerSliderItemImageTwClass} src={mobileImage.url} />
                </picture>
            )}
            {desktopImage && (
                <picture className="hidden lg:block">
                    {desktopImage.additionalSizes.map((size) => (
                        <source key={size.url} srcSet={size.url} media={size.media} />
                    ))}
                    <img className={bannerSliderItemImageTwClass} src={desktopImage.url} />
                </picture>
            )}
            <div className="flex h-full flex-col justify-between bg-secondary p-5 lg:absolute lg:right-[70px] lg:top-1/2 lg:z-above lg:flex lg:h-auto lg:max-w-[500px] lg:-translate-y-1/2 lg:items-end lg:bg-transparent lg:text-right">
                <div className="mb-2 text-2xl uppercase text-white lg:text-4xl">{name}</div>
                {description && <div className="mb-5 text-base text-white">{description}</div>}
                <Link
                    className="w-fit rounded-sm bg-white px-5 py-2 font-bold normal-case text-secondary"
                    href={link}
                    isButton
                >
                    {buttonText}
                </Link>
            </div>
            {isDesktop && (
                <div className="absolute right-0 h-full w-full bg-gradient-to-r from-transparent to-[rgba(27,27,27,0.5)]" />
            )}
        </div>
    );
};
