import { Image } from 'components/Basic/Image/Image';
import NextLink from 'next/link';
import { FC } from 'react';
import { ListedItemPropType } from 'types/simpleNavigation';

type ListItemWithImageProps = {
    listedItem: ListedItemPropType;
};

const TEST_IDENTIFIER = 'blocks-simplenavigation-listitem';

export const ListItemWithImage: FC<ListItemWithImageProps> = ({ listedItem }) => {
    return (
        <NextLink href={listedItem.slug} passHref>
            <a
                className="flex h-full flex-col items-center gap-3 rounded-md bg-white p-4 text-center text-sm no-underline transition hover:text-primary hover:no-underline hover:shadow-md lg:flex-row lg:p-2 lg:text-left"
                data-testid={TEST_IDENTIFIER}
            >
                {listedItem.image && (
                    <Image
                        image={listedItem.image}
                        alt={listedItem.name}
                        type="default"
                        className="!max-h-[56px] min-h-[56px] min-w-[56px] !max-w-[56px]"
                    />
                )}
                <div className="lg:justify-start">
                    {listedItem.name}
                    <span className="text-sm text-greyLight"> ({listedItem.totalCount})</span>
                </div>
            </a>
        </NextLink>
    );
};
