import { ListItem } from './ListItem/ListItem';
import { ListItemWithImage } from './ListItem/ListItemWithImage';
import { FC, HTMLAttributes } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { ListedItemPropType } from 'types/simpleNavigation';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLElement>, never, 'className'>;

type SimpleNavigationProps = NativeProps & {
    listedItems: ListedItemPropType[];
    withImage?: boolean;
};

const TEST_IDENTIFIER = 'blocks-simplenavigation';

export const SimpleNavigation: FC<SimpleNavigationProps> = ({ listedItems, withImage, className }) => {
    return (
        <ul className={className}>
            <ul className="-mb-3 -ml-3 grid auto-rows-fr lg:grid-cols-2 vl:grid-cols-3" data-testid={TEST_IDENTIFIER}>
                {listedItems.map((listedItem, key) => (
                    <li
                        className="mb-3 w-full text-center lg:pl-3 lg:text-left"
                        key={key}
                        data-testid={TEST_IDENTIFIER + '-' + key}
                    >
                        {withImage ? (
                            <ListItemWithImage listedItem={listedItem} />
                        ) : (
                            <ListItem listedItem={listedItem} />
                        )}
                    </li>
                ))}
            </ul>
        </ul>
    );
};
