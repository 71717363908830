import { BlogPreviewCard } from './BlogPreviewCard/BlogPreviewCard';
import { Heading } from 'components/Basic/Heading/Heading';
import { Icon } from 'components/Basic/Icon/Icon';
import { Link } from 'components/Basic/Link/Link';
import { Webline } from 'components/Layout/Webline/Webline';
import { theme } from 'components/Theme/main';
import { useBlogPreviewArticles } from 'connectors/articleInterface/blogArticle/BlogArticle';
import { useBlogUrl } from 'connectors/blogCategory/BlogCategory';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useKeenSlider } from 'keen-slider/react';
import Trans from 'next-translate/Trans';
import { FC } from 'react';

const TEST_IDENTIFIER = 'blocks-blogpreview';

const blogPreviewShowAllLinkTwClass =
    'mt-5 flex gap-3 text-base font-bold uppercase text-greyLight no-underline transition hover:text-grey vl:mt-0 vl:hover:text-greyLighter';

export const BlogPreview: FC = () => {
    const t = useTypedTranslationFunction();
    const blogPreviewItems = useBlogPreviewArticles();
    const isDesktop = useMediaMin('vl');
    const blogUrl = useBlogUrl();

    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        controls: false,
        spacing: 12,
        slidesPerView: 4,
        breakpoints: {
            [theme.mediaQueries.queryNotLargeDesktop]: {
                slidesPerView: 3.2,
                spacing: 24,
                controls: true,
            },
            [theme.mediaQueries.queryTablet]: {
                slidesPerView: 2.2,
                spacing: 24,
                controls: true,
            },
            [theme.mediaQueries.queryMobileXs]: {
                slidesPerView: 1.2,
                spacing: 24,
                controls: true,
            },
        },
    });

    return (
        <>
            {blogPreviewItems.length !== 0 && (
                <div className="relative vl:pb-24">
                    <Webline>
                        <Heading className="mb-5 font-normal uppercase" type="h2">
                            <Trans i18nKey="Our news" components={{ 0: <strong /> }} />
                        </Heading>
                        <div className="keen-slider relative z-above" ref={sliderRef} data-testid={TEST_IDENTIFIER}>
                            {blogPreviewItems.map((blogPreviewItem) => (
                                <BlogPreviewCard
                                    image={blogPreviewItem.image?.sizes?.[0].url}
                                    className="keen-slider__slide"
                                    key={blogPreviewItem.uuid}
                                    tag={blogPreviewItem.blogCategories[0].name}
                                    link={blogPreviewItem.link}
                                    title={blogPreviewItem.name}
                                    description={blogPreviewItem.perex}
                                />
                            ))}
                        </div>
                        {!isDesktop && (
                            <Link className={blogPreviewShowAllLinkTwClass} href={blogUrl ?? '/'}>
                                {t('Show all')} <Icon icon="ArrowRight" iconType="icon" />
                            </Link>
                        )}
                    </Webline>
                    {isDesktop && (
                        <div className="absolute bottom-0 left-0 flex h-[270px] w-[60vw] items-end justify-end rounded-r-md bg-secondary pb-10 pr-10">
                            <Link className={blogPreviewShowAllLinkTwClass} href={blogUrl ?? '/'}>
                                {t('Show all')} <Icon icon="ArrowRight" iconType="icon" />
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
