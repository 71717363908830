import { Heading } from 'components/Basic/Heading/Heading';
import { ProductsSlider } from 'components/Blocks/Product/ProductsSlider';
import { Webline } from 'components/Layout/Webline/Webline';
import { usePromotedProducts } from 'connectors/products/Products';
import Trans from 'next-translate/Trans';
import { FC } from 'react';

const GTM_LIST_NAME = 'homepage promo products' as const;

export const PromotedProducts: FC = () => {
    const promotedProducts = usePromotedProducts();

    if (promotedProducts === undefined) {
        return null;
    }

    return (
        <Webline>
            <Heading className="mb-2 font-normal uppercase" type="h2">
                <Trans i18nKey="<0>Newly added</0> products" components={{ 0: <strong /> }} />
            </Heading>
            <ProductsSlider products={promotedProducts} gtmListName={GTM_LIST_NAME} />
        </Webline>
    );
};
