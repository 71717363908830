import { SearchMetadata } from 'components/Basic/Head/SearchMetadata/SearchMetadata';
import { Banners } from 'components/Blocks/Banners/Banners';
import { BlogPreview } from 'components/Blocks/BlogPreview/BlogPreview';
import { PromotedCategories } from 'components/Blocks/Categories/PromotedCategories/PromotedCategories';
import { PromotedProducts } from 'components/Blocks/Product/PromotedProducts/PromotedProducts';
import { CommonLayout } from 'components/Layout/CommonLayout';
import { blogPreviewVariables } from 'connectors/articleInterface/blogArticle/BlogArticle';
import {
    BlogArticlesQueryDocumentApi,
    PromotedCategoriesQueryDocumentApi,
    PromotedProductsQueryDocumentApi,
    SliderItemsQueryDocumentApi,
} from 'graphql/generated';
import { useGtmStaticPageViewEvent } from 'helpers/gtm/eventFactories';
import { getServerSidePropsWithRedisClient } from 'helpers/misc/getServerSidePropsWithRedisClient';
import { initServerSideProps, ServerSidePropsType } from 'helpers/misc/initServerSideProps';
import { useGtmStaticPageView } from 'hooks/gtm/useGtmStaticPageView';
import { FC } from 'react';
import { nextReduxWrapper } from 'redux/main';

const HomePage: FC<ServerSidePropsType> = () => {
    const gtmStaticPageViewEvent = useGtmStaticPageViewEvent('home');
    useGtmStaticPageView(gtmStaticPageViewEvent);

    return (
        <>
            <SearchMetadata />
            <CommonLayout>
                <div className="flex flex-col gap-10 vl:gap-14">
                    <Banners />
                    <PromotedCategories />
                    <PromotedProducts />
                    <BlogPreview />
                </div>
            </CommonLayout>
        </>
    );
};

export const getServerSideProps = nextReduxWrapper.getServerSideProps((store) =>
    getServerSidePropsWithRedisClient(
        (redisClient) => async (context) =>
            initServerSideProps({
                context,
                store,
                redisClient,
                prefetchedQueries: [
                    { query: PromotedCategoriesQueryDocumentApi },
                    { query: SliderItemsQueryDocumentApi },
                    { query: PromotedProductsQueryDocumentApi },
                    { query: BlogArticlesQueryDocumentApi, variables: blogPreviewVariables },
                ],
            }),
        store,
    ),
);

export default HomePage;
