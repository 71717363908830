import NextLink from 'next/link';
import { FC } from 'react';
import { twJoin } from 'tailwind-merge';

type BlogPreviewCardProps = {
    tag: string;
    link: string;
    image?: string | null;
    title: string;
    description?: string | null;
    className?: string;
};

export const BlogPreviewCard: FC<BlogPreviewCardProps> = ({ tag, image, title, description, link, className }) => {
    return (
        <NextLink href={link}>
            <a
                className={twJoin(
                    'relative flex h-[300px] w-[260px] flex-col justify-between rounded-md !bg-cover !bg-center px-2 pb-7 pt-2 no-underline shadow-md transition hover:no-underline vl:h-[330px] vl:w-[290px]',
                    image &&
                        'before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-gradient-to-t before:from-black/50 before:content-[""]',
                    className,
                )}
                style={{ background: `url(${image}) no-repeat, #555764` }}
            >
                <div className="w-fit rounded-sm bg-secondary px-2 py-1 text-xs font-medium text-white">{tag}</div>
                <div className="px-2 vl:px-7">
                    <h6 className="text-lg font-bold uppercase text-white">{title}</h6>
                    {description && <span className="text-sm leading-6 text-white">{description}</span>}
                </div>
            </a>
        </NextLink>
    );
};
