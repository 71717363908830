import { BannersSliderItem } from 'components/Blocks/Banners/BannersSliderItem/BannersSliderItem';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { FC, useEffect, useRef, useState } from 'react';
import { ImageSizeType, ImageType } from 'types/image';
import { SliderItemType } from 'types/sliderItem';

type BannersSliderProps = {
    sliderItems: SliderItemType[];
    testIdentifier: string;
};

export const BannersSlider: FC<BannersSliderProps> = ({ sliderItems, testIdentifier }) => {
    const [loadedImageUrls, setLoadedImageUrls] = useState<{ [key: string]: boolean }>({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pause, setPause] = useState(false);
    const timer = useRef<NodeJS.Timer | null>(null);
    const sliderBoxRef = useRef<HTMLDivElement>(null);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
        loop: true,
        duration: 1000,
        slideChanged: (slider) => {
            setCurrentSlide(slider.details().relativeSlide);
        },
        dragStart: () => {
            setPause(true);
        },
        dragEnd: () => {
            setPause(false);
        },
        created(slider) {
            setLoadedImageUrls((currentLoadedImageUrls) => {
                const newLoadedImageUrls = { ...currentLoadedImageUrls };
                const slidesPerView = slider.options().slidesPerView;
                if (slidesPerView !== undefined) {
                    for (let i = 0; i < slidesPerView; i++) {
                        newLoadedImageUrls[i] = true;
                    }

                    if (slider.options().centered) {
                        newLoadedImageUrls[sliderItems.length - 1] = true;
                    }
                }
                return newLoadedImageUrls;
            });
        },
    });

    useEffect(() => {
        setLoadedImageUrls((currentLoadedImageUrls) => {
            const newLoadedImageUrls = { ...currentLoadedImageUrls };
            newLoadedImageUrls[currentSlide] = true;

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (slider !== null && slider.options().centered) {
                newLoadedImageUrls[Math.min(currentSlide + 1, sliderItems.length - 1)] = true;
            }
            return newLoadedImageUrls;
        });
    }, [currentSlide, sliderItems.length, slider]);

    useEffect(() => {
        const setPauseTrue = () => {
            setPause(true);
        };
        const setPauseFalse = () => {
            setPause(false);
        };

        const sliderBox = sliderBoxRef.current;

        if (sliderBox !== null) {
            sliderBox.addEventListener('mouseover', setPauseTrue);
            sliderBox.addEventListener('mouseout', setPauseFalse);
        }

        return () => {
            sliderBox?.removeEventListener('mouseover', setPauseTrue);
            sliderBox?.removeEventListener('mouseout', setPauseFalse);
        };
    }, [sliderRef]);

    useEffect(() => {
        timer.current = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!pause && slider !== null) {
                slider.next();
            }
        }, 5000);
        return () => {
            if (timer.current !== null) {
                clearInterval(timer.current);
            }
        };
    }, [pause, slider]);

    return (
        <div
            className="flex max-h-none flex-col pb-0 lg:max-h-[505px] lg:flex-row"
            ref={sliderBoxRef}
            data-testid={testIdentifier}
        >
            <div ref={sliderRef} className="keen-slider">
                {sliderItems.map((sliderItem, index) => (
                    <BannersSliderItem
                        key={index}
                        mobileImage={getBannersSliderItemImage(sliderItem.mobileImages, loadedImageUrls[index])}
                        desktopImage={getBannersSliderItemImage(sliderItem.webImages, loadedImageUrls[index])}
                        link={sliderItem.link}
                        name={sliderItem.name}
                        description={sliderItem.description}
                        buttonText={sliderItem.extendedText}
                    />
                ))}
            </div>
        </div>
    );
};

export const getBannersSliderItemImage = (image: ImageType | null, isImageLoaded: boolean): ImageSizeType | null => {
    if (!isImageLoaded || image === null || image.sizes === null) {
        return null;
    }

    return image.sizes.find((i) => i.size === 'default') ?? null;
};
