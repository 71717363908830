import { Heading } from 'components/Basic/Heading/Heading';
import { SimpleNavigation } from 'components/Blocks/SimpleNavigation/SimpleNavigation';
import { Webline } from 'components/Layout/Webline/Webline';
import { usePromotedCategories } from 'connectors/categories/PromotedCategories';
import Trans from 'next-translate/Trans';
import { FC } from 'react';

export const PromotedCategories: FC = () => {
    const promotedCategories = usePromotedCategories();

    if (promotedCategories === undefined) {
        return null;
    }

    return (
        <Webline>
            <Heading className="mb-5 font-normal uppercase" type="h2">
                <Trans i18nKey="The right offer for your needs" components={{ 0: <strong /> }} />
            </Heading>
            <SimpleNavigation listedItems={promotedCategories} />
        </Webline>
    );
};
