import { SimpleFlagType } from 'types/flag';
import { twMergeCustom } from 'utils/twMerge';

type ProductFlagsProps = {
    flags: SimpleFlagType[];
};

const TEST_IDENTIFIER = 'blocks-product-flags-';

export const ProductFlags: FC<ProductFlagsProps> = ({ flags, className }) => {
    if (flags.length === 0) {
        return null;
    }

    return (
        <>
            {flags.map(({ name, rgbColor }, key) => (
                <div
                    className={twMergeCustom(
                        'mb-0.5 mr-auto inline-flex rounded-sm bg-secondary px-2 py-1 text-xs uppercase !leading-3 text-white no-underline transition hover:text-black',
                        className,
                    )}
                    key={key}
                    style={{ background: rgbColor }}
                    data-testid={TEST_IDENTIFIER + key}
                >
                    {name}
                </div>
            ))}
        </>
    );
};
