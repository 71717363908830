import { Icon } from 'components/Basic/Icon/Icon';
import { Image } from 'components/Basic/Image/Image';
import { ProductFlags } from 'components/Blocks/Product/Flags/ProductFlags';
import { ProductPrice } from 'components/Blocks/Product/Price/ProductPrice';
import { onClickProductDetailGtmEventHandler } from 'helpers/gtm/eventHandlers';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { useCallback } from 'react';
import { useShopsysSelector } from 'redux/main';
import { GtmListNameType } from 'types/gtm';
import { ListedProductType } from 'types/product';
import { twMergeCustom } from 'utils/twMerge';

type ProductItemProps = {
    product: ListedProductType;
    listIndex: number;
    gtmListName: GtmListNameType;
};

const getTestIdentifier = (catalogNumber: string) => 'blocks-product-list-listeditem-' + catalogNumber;

export const ProductItem: FC<ProductItemProps> = ({ product, listIndex, gtmListName, className }) => {
    const { url } = useShopsysSelector((state) => state.domain);
    const { t } = useTranslation();

    const onProductDetailRedirectHandler = useCallback(
        async (product: ListedProductType, listName: GtmListNameType, index: number) => {
            await onClickProductDetailGtmEventHandler(product, listName, index, url);
        },
        [url],
    );

    return (
        <div
            className={twMergeCustom('w-[calc(50%-5px)] lg:w-[calc(33%-5px)]', className)}
            data-testid={getTestIdentifier(product.catalogNumber)}
        >
            <div className="group relative flex h-full flex-col rounded-md bg-white p-5 text-left hover:no-underline lg:hover:z-above lg:hover:bg-white lg:hover:shadow-md">
                <NextLink href={product.slug} passHref>
                    <a
                        className="relative flex h-full flex-col no-underline hover:no-underline"
                        onClick={() => onProductDetailRedirectHandler(product, gtmListName, listIndex)}
                    >
                        <div className="relative mb-2 flex h-48 w-full items-center justify-center bg-transparent text-[0px]">
                            <Image
                                className="!max-h-48 !max-w-[192px] mix-blend-multiply lg:group-hover:mix-blend-multiply"
                                image={product.image}
                                type="list"
                                alt={product.fullName}
                            />
                            <div className="absolute left-4 top-2 flex flex-col text-[0px]">
                                <ProductFlags flags={product.flags} />
                            </div>
                        </div>
                        {product.variantsCount && (
                            <div className="mb-3 flex w-fit gap-2 rounded-sm bg-greyVeryLight p-1 text-sm">
                                <Icon icon="Variants" iconType="icon" />
                                {t('{{ count }} variants', { count: product.variantsCount })}
                            </div>
                        )}
                        <div className="mt-auto flex flex-1 flex-col justify-between bg-transparent no-underline">
                            <h3 className="font-sm mb-5 block overflow-hidden break-words font-bold text-black">
                                {product.name} {product.nameSuffix}
                            </h3>
                            <ProductPrice productPrice={product.price} />
                        </div>
                    </a>
                </NextLink>
            </div>
        </div>
    );
};
