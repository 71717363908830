import { Icon } from 'components/Basic/Icon/Icon';
import NextLink from 'next/link';
import { FC } from 'react';
import { ListedItemPropType } from 'types/simpleNavigation';

type ListItemProps = {
    listedItem: ListedItemPropType;
};

const TEST_IDENTIFIER = 'blocks-simplenavigation-listitem';

export const ListItem: FC<ListItemProps> = ({ listedItem }) => {
    return (
        <NextLink href={listedItem.slug} passHref>
            <a
                className="keen-slider__slide flex h-full items-center justify-between gap-2 rounded-md bg-white p-8 text-left text-lg font-bold uppercase no-underline transition hover:text-primary hover:no-underline hover:shadow-md"
                data-testid={TEST_IDENTIFIER}
            >
                {listedItem.name}
                <div className="flex min-h-[25px] min-w-[25px] items-center justify-center rounded-[50%] bg-primary">
                    <Icon className="h-3 w-3 text-white" icon="ArrowRight" iconType="icon" />
                </div>
            </a>
        </NextLink>
    );
};
